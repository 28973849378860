<template>
  <div>
    <div style="background-color: #f5f6f8; padding-top: 50px; min-height: 500px;">

      <div style="width: 960px; text-align: center; margin: 0 auto; background: #f5f6f8; border-radius: 15px; border-left:1px solid rgba(191, 200, 210, 0.3); border-right:1px solid rgba(191, 200, 210, 0.3)">


        <template v-if="pageLoaded">

          <div style=" margin: 0 auto; text-decoration: none!important;">

            <div v-if="style_type !== 'list'" :class="['cardA1', 'generalCard', 'card_'+style_type, color]" :style="'background-size: cover; background-image: url('+image_url+');'">
              <h1 v-if="style_type === 'bottom_title'" class="bottom_text_placeholder" style="margin: 0">
                <p class="subheader">{{subheader}}</p>
                <p class="title" v-html="header"></p>
                <p class="subheader2">{{subheader2}}</p>
              </h1>
              <h1 v-if="style_type !== 'long_title'" style="margin: 0">
                <p class="subheader">{{subheader}}</p>
                <p class="title" style="color: #2D3640; margin: 0" v-html="header"></p>
                <p class="subheader2">{{subheader2}}</p>
              </h1>
            </div>
            <div v-if="style_type === 'list'" :class="['generalCard', 'card_'+style_type, color]">
              <p class="subheader">{{subheader}}</p>
              <p class="title">{{header}}</p>
            </div>
          </div>



          <div class="blogContent" style="background-color: #f5f6f8;">

            <div class="blog-bread" v-if="blog_sub_category_id">
              <div style="display: flex">
                <router-link to="/blog" class="item">Tüm Yazılar</router-link>
                <img src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg">
                <router-link :to="'/blog/kategori/'+blog_category_slug"  class="item">{{ blog_category_name }}</router-link>
                <img src="https://gcdn.bionluk.com/site/icon/tansel_sag_ok_gri.svg">
                <router-link :to="'/blog/kategori/'+blog_category_slug+'/'+blog_sub_category_slug" class="item">{{blog_sub_category_name}}</router-link>
              </div>
              <div style="margin-top: 10px;">
                <router-link  :to="'/blog/kategori/'+blog_category_slug+'/'+label.slug" v-for="(label, lindex) in labels" :key="lindex" style="padding-right:14px;color: #2cad87; font-size: 14px; font-weight: 600">#{{ label.title }}</router-link>
              </div>

            </div>


            <h1 v-if="style_type === 'long_title'" class="long-main-title">{{header}}</h1>

            <div class="blogInside">

                <span  v-for="(list, index) in htmlList" :key="index">

                  <div v-if="list.split(':portfolio:')[1]">
                      <Portfolio :uuid="list.split(':portfolio:')[1]"/>
                    </div>

                    <div v-else-if="list.split(':gig:')[1]">
                      <List :slug="actualPath" :impression-u-u-i-d="impressionUUID" type="gig" :list="list.split(':gig:')[1]"/>
                    </div>

                    <div v-else-if="list.split(':username:')[1]">
                      <List :slug="actualPath" :impression-u-u-i-d="impressionUUID" type="username" :list="list.split(':username:')[1]"/>
                    </div>

                   <div v-else-if="list.split(':imageText:')[1]">
                       <template v-if="list.split(':imageText:')[1].split('--')">
                        <p class="caption-link">
                        {{  list.split(':imageText:')[1].split('--')[0] }}
                            <template v-if="list.split(':imageText:')[1].split('--')">
                               <router-link :to="'/'+list.split(':imageText:')[1].split('--')[1]" class="caption-a">
                             {{list.split(':imageText:')[1].split('--')[1]}}
                           </router-link>
                          </template>
                       </p>
                       </template>
                      <template v-else>
                         <p class="caption-link">{{ list.split(':imageText:')[1]}}</p>
                      </template>

                  </div>

                    <p v-else v-html="list"></p>

                </span>

            </div>
            <div class="hiddendiv"></div>
            <div class="freelancer-list">
              <div v-for="(list, index) in renderedLists" :key="index" class="freelancer-list-container">
                <div class="freelancer-list-div">
                  <div :class="'freelancer-list-div-div_'+list.type">
                    <router-link target="_blank" :to="list.action.url">
                      <img v-if="list.type == 'gig'" class="freelancer-list-avatar" :src="list.image_url"/>
                      <img v-if="list.type == 'user'" class="freelancer-list-avatar_s" :src="list.image_url"/>
                    </router-link>
                  </div>
                  <div class="freelancer-list-second-flex">
                    <router-link class="nounderlinehover" target="_blank" :to="list.action.url">
                      <p v-if="list.type == 'user'" class="freelancer-list-username">{{list.title}}</p>
                      <p v-if="list.type == 'gig'" class="freelancer-list-user-title" style="padding-top: 6px;">{{list.title}}</p>
                      <p v-if="list.type == 'user'" class="freelancer-list-user-title_s">{{list.subtitle}}</p>
                      <p v-if="list.type == 'gig'" style="font-size: 14px; font-weight: 500; color: #2d3640; padding-top: 4px;">{{list.subtitle}}</p>
                    </router-link>
                  </div>
                  <div v-if="list.type == 'user'" style="width: 400px; align-self: center; align-items: center; align-content: center; height: 50px; ">
                    <div style="text-align: right;margin-right: 48px; padding-top: 5px;">
                        <span @click="sendMessage(list)" class="send_mes" :style="buttonLoading ? 'opacity:0.7': 'opacity:1'">
                          MESAJ GÖNDER
                        </span>
                    </div>

                  </div>
                  <div v-if="list.type == 'gig'" style=" width: 200px; align-self: center; align-items: center; align-content: center; height: 50px; ">
                    <div style="text-align: right;margin-right: 24px; padding-top: 5px;">
                        <router-link target="_blank" :to="list.action.url" class="nounderlinehover" style=" font-weight:600;background-color: #f0f0f7; border-radius: 120px; font-size: 18px; color: #007aff;  padding: 5px 23px 7px 23px">
                          {{list.action.text}}<span style="font-size: 14px;"> TL</span>
                        </router-link>
                      <p style="color: #8e8e93; font-size: 12px; padding-top: 6px; font-weight: 400;  text-align: left; margin-left: 58px">{{list.action.subtext}}</p>
                    </div>


                  </div>
                </div>
                <div v-if="index !== renderedLists.length - 1 && list.type == 'gig'" class="freelancer-list-border" ></div>
                <div v-if="index !== renderedLists.length - 1 && list.type == 'user'" class="freelancer-list-border_s" ></div>
              </div>
            </div>


            <div v-if="footer_div.sellerName !== ''" class="hiddendiv20"></div>
            <div v-if="footer_div.sellerName !== ''" style="padding-top:10px;height: 330px; background-color: #eff1f3; margin-left:-47px; margin-right: -47px;">

              <div style="margin:0 auto; width:100%;text-align:center;">
                <p v-if="footer_div.external_id_text" style="font-size: 14px; padding-top: 5px; font-weight:400;color: #2d3640">{{ footer_div.external_id_text }}</p>
                <div v-else class="hiddendiv20"></div>
                <router-link :to="footer_div.action.url">
                <img style="height: 124px; width: 124px; border-radius: 20px; margin-top: 25px; " :src="footer_div.seller_avatar_url">
                </router-link>
                <p style="color: #007aff; margin-top: 16px; font-weight: 600; font-size: 24px;">{{footer_div.sellerName}}</p>
                <p style="line-height: 19px; height: 20px; margin-top: 8px; margin-bottom: 16px;
    font-size: 14px; font-weight: 300;

    color: rgb(139, 149, 161);">{{footer_div.seller_title}}</p>

                <div style="margin-top: 24px;">

                  <router-link :to="footer_div.action.url" class="nounderlinehover" style=" background-color: #007aff;color: white;  font-weight:600;border-radius: 120px; font-size: 16px; cursor: pointer;  padding: 5px 23px 7px 23px">
                    {{footer_div.action.text}}
                  </router-link>

                </div>
              </div>
            </div>


            <div class="hiddendiv"></div>
            <div class="hiddendiv10"></div>

            <div style="text-align: center; margin-bottom: 40px;">
              <router-link to="/blog" class="blogGo">
                Tüm Yazılara Git
              </router-link>
            </div>

            <div style="text-align: center">
                <span @click="showShareButtonDetail" v-if="showShareButton" class="blogShare">
                  <img style="display: inherit!important;width: 16px; margin-right: 5px;" src="https://gcdn.bionluk.com/site/icon/iosShare.png"/>
                  Yazıyı Paylaş
                </span>
              <div v-if="!showShareButton" @click="clickedShareButton">
                <social-sharing :url="url" inline-template>
                  <div style="margin-top: 20px; ">
                    <network network="facebook" style="cursor: pointer;">
                      <img style="display: inherit!important;height: 25px;" :src="$root.CDN_SITE + 'icon/facebook-box.svg'"/>
                    </network>
                    <network network="linkedin" style="cursor: pointer; margin-left: 10px;">
                      <img style="display: inherit!important;height: 25px;" :src="$root.CDN_SITE + 'icon/linkedin-box.svg'"/>
                    </network>
                    <network network="twitter" style="cursor: pointer; margin-left: 10px;">
                      <img style="display: inherit!important;height: 25px;" :src="$root.CDN_SITE + 'icon/twitter-box.svg'"/>
                    </network>
                  </div>
                </social-sharing>
              </div>
            </div>
            <div class="hiddendiv"></div>

            <div class="hiddendiv"></div>
            <div class="hiddendiv"></div>

          </div>


        </template>
        <div v-else class="loader" style="text-align: center; margin:20px auto;"></div>
      </div>


    </div>
  </div>
</template>

<script>
  import Portfolio from "../../../../components/todayItems/portfolio.vue";
  import List from "../../../../components/todayItems/list.vue";

  export default {
    name: "src-pages-body-staticContents-blog-detail-v2",
    components: {Portfolio, List},
    data() {
      return {
        impressionUUID:null,
	      buttonLoading:false,
        showShareButton:true,
        pageLoaded: false,
        blogID: null,
        name: null,
        content: null,
        brief: null,
        commentsCount: null,
        createdAt: null,
        image_url:null,
        url: window.location.href,
        comments: [],
        newComment: '',
        blogLang: null,
        renderedLists:[],
        style_type:null,
        size_type:null,
        color:null,
        header:null,
        subheader: null,
        subheader2: null,
        footer_div: {
          sellerName:"",
          seler_id:"",
          seller_avatar_url:"",
          seller_title:"",
          action:{
            text:"",
            action:"",
            url:""
          }
        },
        htmlList:[],

        blog_category_id:null,
        blog_category_name:null,
        blog_category_slug:null,
        blog_sub_category_id:null,
        blog_sub_category_name:null,
        blog_sub_category_slug:null,
        labels:[]
      };
    },
    methods: {
      explodeImageText(text){

        let splitMore = text.split('--')
        if(splitMore[1]){
          return splitMore[0] + '<a class="captiona">'+splitMore[1]+'</a>';
        } else {
          return text
        }
      },
	    clickedShareButton(){
		    this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'blog share buttons'});
	    },
	    showShareButtonDetail(){

        this.showShareButton = false;
      },
      showPortfolioDetailModal(uuid) {
        // this.$store.state.showCase.data = portfolio;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: uuid}});
      },
      getBlogv2() {
        this.api.content.getBlogv2(this.actualPath)
          .then(({data}) => {
            let result = data;
            this.pageLoaded = true;
            if (result.success) {
              this.blogID = result.data.topic.blog_id;
              this.name = result.data.topic.name;
              this.content = result.data.topic.description;
              this.brief = result.data.topic.brief;
              this.image_url = result.data.topic.image_url;
              this.commentsCount = result.data.topic.comment_count;
              this.createdAt = Number(result.data.topic.created_at);

              this.renderedLists = result.data.topic.renderedLists;
              this.style_type = result.data.topic.style_type;
              this.size_type = result.data.topic.size_type;
              this.color = result.data.topic.color;
              this.header = result.data.topic.header;
              this.subheader = result.data.topic.subheader;
              this.subheader2 = result.data.topic.subheader2;
              this.footer_div = result.data.topic.footer_div;
              this.htmlList = result.data.topic.htmlList;

              this.blog_category_id = result.data.topic.blog_category_id;
              this.blog_category_name = result.data.topic.blog_category_name;
              this.blog_category_slug = result.data.topic.blog_category_slug;
              this.blog_sub_category_id = result.data.topic.blog_sub_category_id;
              this.blog_sub_category_name = result.data.topic.blog_sub_category_name;
              this.blog_sub_category_slug = result.data.topic.blog_sub_category_slug;
              this.labels = result.data.topic.labels;

              this.comments = result.data.blog_topic_comments;
              this.comments.forEach(comment => {
                comment.created_at  = this.calculateElapsedTime(Number(comment.created_at))
              });
              this.blogLang = result.data.topic.lang;
              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;
              this.setFacebookMetaProperties(result.data.meta.facebookMeta);

              this.Helper.trackEvents.pageView(this.Helper.blogDetailPageProps(result.data.topic.blog_id, this.$store.state.routerParams[0]));
            } else {
              this.$toasted.global.errorToast({description: result.message});


              let meta = document.createElement('meta');
              meta.name = "render:status_code";
              meta.content = "301";
              document.getElementsByTagName('head')[0].appendChild(meta);


              //let meta2 = document.createElement('meta');
              //let meta2content = "0,url=https://bionluk.com" + metaLocation;
              //meta2.setAttribute("http-equiv", "refresh");
              //meta2.setAttribute("content", meta2content);
              //document.getElementsByTagName('head')[0].appendChild(meta2);
              let meta3 = document.createElement('meta');
              let meta3content = "Location:https://bionluk.com" + result.data.redirect_url;
              meta3.setAttribute("name", "render:header");
              meta3.setAttribute("content", meta3content);
              document.getElementsByTagName('head')[0].appendChild(meta3);


              this.$router.push(result.data.redirect_url);
            }
          })
          .catch(err => {
            console.log(err);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      saveBlogComment() {
        this.saveComment();
        this.api.content.saveBlogComment(this.blogID, this.newComment, this.blogLang)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              this.newComment = "";

            } else {
              this.revertComment();
              this.$toasted.global.errorToast({description: result.message});
              this.$router.push(result.data.redirect_url);
            }

          })
          .catch(err => {
            this.revertComment();
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },
      saveComment() {
        let savedComment = {
          user: {
            username: this.user.username,
            avatar_url: this.user.avatar_url
          },
          comment: this.newComment,
          created_at: this.calculateElapsedTime(+new Date() - 1000)
        };

        this.comments.push(JSON.parse(JSON.stringify(savedComment)));
      },
      revertComment() {
        this.comments.pop();
        this.newComment = "";
      },
      sendMessage(user) {


        if (!this.isLoggedIn) {
          this.$router.push('/login/');
        } else {
        	if(this.buttonLoading === false){
		        this.buttonLoading = true;
		        this.api.workstation.chatOpen(user.action.screenProps.username, 0,1)
			        .then(({data}) => {
				        let result = data;
				        if (result.success) {
					        let conversationSendData = {
						        r_username: user.action.screenProps.username,
						        r_avatar_url: user.image_url,
						        r_id:  user.action.screenProps.id,
						        r_uuid: user.action.screenProps.uuid,
						        fromWhere: 'Blog',
						        c_uuid: result.data.c_uuid,
						        blogID:this.blogID
					        };
					        this.EventBus.$emit('externalOpenChat', conversationSendData);
				        } else {
					        this.$toasted.global.errorToast({description: result.message});
				        }
				        this.buttonLoading = false;
			        })
			        .catch(err => {
				        this.buttonLoading = false;
				        this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			        });
          }
        }

      }
    },
    computed: {
      actualPath() {
        return this.$store.state.routerParams[0];
      }
    },
    watch: {
      actualPath(newValue, oldValue) {
        if (newValue) {
          this.pageLoaded = false;
          this.getBlogv2();
        }
      }
    },
    created() {
      this.impressionUUID = this.Helper.generateUUID();
      this.pageLoaded = false;
      this.getBlogv2();
    }
  }
</script>

<style scoped lang="scss">

.caption-link{
  font-size:15px; text-align: center; margin-top: 10px; font-weight: 300;
  .caption-a{
    color: #fe5b5c;
    text-decoration: none;
    font-weight: 600;
    &:hover{
      text-decoration: underline;
    }
  }
}

.blogInside{
  font-size: 18px;
  line-height: 2;
  color: #5e6b79;
  margin-top: 30px;
}

.blog-bread{
   margin-top: 20px;
  margin-bottom: 10px;
  .item{
    font-size: 14px;
    text-align: left;
    color: #8b95a1;
  }
  img{
    margin-left: 6px; margin-right: 5px;
  }
}

.long-main-title{
  text-align: left;
  margin: 5px 0 15px;
  font-weight: bold;
  font-size: 36px!important;
  color: #2D3640;
  line-height: 40px;
}

.send_mes{
  cursor: pointer; font-weight:600;background-color: #f0f0f7; border-radius: 120px; font-size: 16px; color: #007aff;  padding: 5px 23px 7px 23px
}

.blogContent{
  text-align: left;
  position: relative;
  background-color: #f4f5f7;
  box-shadow: 0 0px 0px 0 rgba(40, 60, 98, 0.1);
  padding: 0 47px 20px;
}

.blogContent h2 {
  font-weight: 600;
  font-size: 28px !important;
  color: #2d3640 !important;
  margin-bottom: 5px;
  text-align: left!important;
}

.blogContent h2 strong {
  font-weight: 600;
  font-size: 28px !important;
  color: #2d3640 !important;
  margin-bottom: 5px;
  text-align: left!important;
}

.generalCard{
  text-align: left;
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0px 20px 0 rgba(40, 60, 98, 0.1);
}

.generalCard:hover{
  text-decoration: none!important;
}

.cardA1{
  height: 390px; background-position: center;
}

.freelancer-list{
  margin-right: 14px
}

.freelancer-list-div{
  display: flex; height: 100px;
}

.freelancer-list-div-div_user{
  align-self: center;
  width: 132px;
}

.freelancer-list-div-div_gig{
  align-self: center;
  width: 187px;
}

.freelancer-list-avatar{
  height:80px;  border:1px solid #dde3eb;border-radius: 4px;
}

.freelancer-list-avatar_s{
  height:80px;  border:1px solid #dde3eb;border-radius: 12px;
}

.freelancer-list-second-flex{
  margin-left: 32px; height: 80px; align-self: center; width: 500px;
}

.freelancer-list-username{padding-top: 5px;
  line-height:36px;font-size: 24px; font-weight: 400; color: rgb(36, 36, 36);
}

.freelancer-list-user-title{
  line-height:19px; font-size: 19px; font-style: italic; color: rgb(139, 149, 161);
}

.freelancer-list-border{
  margin-left:177px;border-bottom: 1px solid #e1e1e1; margin-right: 32px;
}

.freelancer-list-border_s{
  margin-left:110px;border-bottom: 1px solid #e1e1e1; margin-right: 32px;
}

.freelancer-list-user-title_s{
  line-height:19px; font-size: 15px; font-style: italic; color: rgb(139, 149, 161);
}

.bottom_text_placeholder {
  position: absolute;
  bottom: 16px;
  left: 20px;
  right: 20px;
}

.blogGo{
  background-color: #F3F4F6; width: 140px; padding: 6px 24px  10px;
  color:#007aff;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;

}

.blogGo:hover{
  background-color: #efeff4;
}

.blogShare{
    background-color: #F3F4F6; width: 140px; padding: 6px 24px  10px;
    color:#007aff;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

}

.blogShare:hover{
    background-color: #efeff4;
}

</style>
