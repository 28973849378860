<template>
  <div>
    <div v-for="(list, index) in renderedLists" :key="index" class="freelancer-list-container">
      <div class="freelancer-list-div">
        <div :class="'freelancer-list-div-div_'+list.type">
          <router-link
            @contextmenu.native="handleGlobalClick('Blog','Blog Modal', list.type === 'user' ? 'profile' : list.type, impressionUUID, list.type === 'gig' ? {gig_id:list.action.mobile.gig_id, seller_id:list.action.mobile.seller_id} : {})"
            @click.native="handleGlobalClick('Blog','Blog Modal', list.type === 'user' ? 'profile' : list.type, impressionUUID, list.type === 'gig' ? {gig_id:list.action.mobile.gig_id, seller_id:list.action.mobile.seller_id} : {})"
            target="_blank" :to="list.action.url">
            <img v-if="list.type === 'gig'" class="freelancer-list-avatar" :src="list.image_url"/>
            <img v-if="list.type === 'user'" class="freelancer-list-avatar_s" :src="list.image_url"/>
          </router-link>
        </div>
        <div class="freelancer-list-second-flex">
          <router-link
            @contextmenu.native="handleGlobalClick('Blog','Blog Modal', list.type === 'user' ? 'profile' : list.type, impressionUUID, list.type === 'gig' ? {gig_id:list.action.mobile.gig_id, seller_id:list.action.mobile.seller_id} : {})"
            @click.native="handleGlobalClick('Blog','Blog Modal', list.type === 'user' ? 'profile' : list.type, impressionUUID, list.type === 'gig' ? {gig_id:list.action.mobile.gig_id, seller_id:list.action.mobile.seller_id} : {})"
            class="nounderlinehover" target="_blank" :to="list.action.url">
            <p v-if="list.type === 'user'" class="freelancer-list-username">{{list.title}}</p>
            <p v-if="list.type === 'gig'" class="freelancer-list-user-title" style="padding-top: 6px;">{{list.title}}</p>
            <p v-if="list.type === 'user'" class="freelancer-list-user-title_s">{{list.subtitle}}</p>
            <p v-if="list.type === 'gig'" style="font-size: 14px; font-weight: 500; color: #2d3640; padding-top: 4px;">{{list.subtitle}}</p>
          </router-link>
        </div>
        <div v-if="list.type === 'user'" style="width: 400px; align-self: center; align-items: center; align-content: center; height: 50px; ">
          <div style="text-align: right;margin-right: 48px; padding-top: 5px;">
            <span @click="sendMessage(list)" :style="buttonLoading ? 'opacity:0.7' : 'opacity:1'" class="send_mes">
              MESAJ GÖNDER
            </span>
          </div>
        </div>
        <div v-if="list.type === 'gig'" style="width: auto; align-self: center; align-items: center; align-content: center; height: 50px; ">
          <div style="text-align: right;margin-right: 24px; padding-top: 5px;">
            <router-link
              @contextmenu.native="handleGlobalClick('Blog','Blog Modal', list.type === 'user' ? 'profile' : list.type, impressionUUID, list.type === 'gig' ? {gig_id:list.action.mobile.gig_id, seller_id:list.action.mobile.seller_id} : {})"
              @click.native="handleGlobalClick('Blog','Blog Modal', list.type === 'user' ? 'profile' : list.type, impressionUUID, list.type === 'gig' ? {gig_id:list.action.mobile.gig_id, seller_id:list.action.mobile.seller_id} : {})"
              target="_blank" :to="list.action.url" class="nounderlinehover" style=" height: 30px; font-weight:600;background-color: #f0f0f7; border-radius: 120px; font-size: 18px; color: #007aff;  padding: 1px 23px 5px 23px">
              {{list.action.text}}<span style="font-size: 14px;"> TL</span>
            </router-link>
            <p style="color: #8e8e93; font-size: 12px; padding-top: 6px; font-weight: 400;  width: 80px; float: right; text-align: center;">{{list.action.subtext}}</p>
          </div>
        </div>
      </div>
      <div v-if="index !== renderedLists.length - 1 && list.type == 'gig'" class="freelancer-list-border" ></div>
      <div v-if="index !== renderedLists.length - 1 && list.type == 'user'" class="freelancer-list-border_s" ></div>
    </div>

    <div class="line" style="margin-top: 10px; margin-bottom: 10px;"></div>
  </div>
</template>

<script>

  import Loader from "../toolbox/loader.vue";


  export default {
    components:{Loader},
    props: ["list","type", 'blogID', "impressionUUID", "slug"],
    data() {
      return {
        loader:true,
        buttonLoading: false,

        renderedLists:[
          {
            action_url:'',
            image_url:'',
            title:'',
            subtitle:'',
            action:{
              action:'',
              subtext:'',
              text:'',
              url:''

            },
          }
        ]
      };
    },
    methods: {
      sendMessage(user) {
	      if (!this.isLoggedIn) {
		      this.$router.push('/login/');
	      } else {
		      if (this.buttonLoading === false) {
			      this.buttonLoading = true;
			      this.api.workstation.chatOpen(user.action.screenProps.username, 0,1)
				      .then(({data}) => {
					      let result = data;
					      if (result.success) {
						      let conversationSendData = {
							      r_username: user.action.screenProps.username,
							      r_avatar_url: user.image_url,
							      r_id:  result.data.receiver.id,
							      r_uuid: result.data.receiver.uuid,
							      fromWhere: 'Blog',
							      c_uuid: result.data.c_uuid,
							      blogID:this.blogID
						      };
						      this.EventBus.$emit('externalOpenChat', conversationSendData);
					      } else {
						      this.$toasted.global.errorToast({description: result.message});
					      }
					      this.buttonLoading = false;
				      })
				      .catch(err => {
					      this.buttonLoading = false;
					      this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
				      });
		      }
	      }
      }
    },
    created() {
      this.api.content.renderList(this.type, this.list)
        .then(({data}) => {
          let result = data;
          if (result.success) {
            let trackGigObj = [];
            let k = 1;
            result.data.list.forEach((rList)=> {
              if(rList.type === 'gig'){
                trackGigObj.push({
                  gig_id: rList.action.mobile.gig_id,
                  seller_id: rList.action.mobile.seller_id,
                  order_id: k
                })
                k++;
              }
            });
            if(trackGigObj.length > 0){
              let trackingProps = {
                page:'blogdetail',
                placement:'list',
                info:trackGigObj,
                term: this.slug,
                title: this.impressionUUID
              };
              this.Helper.trackEvents.customEvent("gig_impressions", trackingProps);
            }

            this.renderedLists = result.data.list;
            this.loader = false;
          } else {
            this.$toasted.global.errorToast({description: result.message});
            this.loader = false;
          }
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          this.loader = false;
        });

    }
  }
</script>

<style scoped lang="scss">


.nounderlinehover{
  text-decoration: none!important;
  display: block;
}

  .send_mes{
    cursor: pointer; font-weight:600;background-color: #f0f0f7; border-radius: 120px; font-size: 16px; color: #007aff;  padding: 5px 23px 7px 23px
  }

  .freelancer-list{
    margin-right: 14px
  }

  .freelancer-list-div{
    display: flex; height: 100px;
  }

  .freelancer-list-div-div_user{
    align-self: center;
    width: 132px;

  }

  .freelancer-list-div-div_gig{
    align-self: center;
    width: 187px;

  }

  .freelancer-list-avatar{
    height:80px;  border:1px solid #dde3eb;border-radius: 4px;
  }

  .freelancer-list-avatar_s{
    height:80px;  border:1px solid #dde3eb;border-radius: 12px;
  }


  .freelancer-list-second-flex{
    margin-left: 32px; height: 80px; align-self: center; width: 500px;
  }

  .freelancer-list-username{padding-top: 5px;
    line-height:36px;font-size: 24px; font-weight: 400; color: rgb(36, 36, 36)
  }

  .freelancer-list-user-title{

    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2d3640;


  }

  .freelancer-list-border{
    margin-left:177px;border-bottom: 1px solid #e1e1e1; margin-right: 32px;
  }

  .freelancer-list-border_s{
    margin-left:110px;border-bottom: 1px solid #e1e1e1; margin-right: 32px;
  }


  .freelancer-list-user-title_s{
    line-height:19px; font-size: 15px; font-style: italic; color: #5e6b79;
  }

</style>
