<template>
  <div v-show="!hideIt">
    <loader v-if="loader"></loader>
    <div v-else class="portfolio-modal-review-main">

      <div class="user-info-div">

        <div style="display: flex; height: 60px; ">
          <router-link :to="'/'+portfolio.user.username">
            <img class="avatar" style="display: inherit!important;" :src="portfolio.user.avatar_url"/>
          </router-link>
          <div class="title-and-username">
            <p class="title">{{ portfolio.name }}</p>
            <p class="sub-title" v-if="portfolio.rating_user.id">{{ portfolio.user.username }}, <router-link :to="'/'+portfolio.rating_user.username">{{portfolio.rating_user.username}}</router-link> için yaptı.</p>
            <p v-else class="sub-title">{{portfolio.category_sub_name}}</p>
          </div>
        </div>
        <div style=" display: flex; ">
          <template v-if="!user || portfolio.user.username !== user.username">
            <div v-show="portfolio.likeStatus.action === 1">
              <custom-button :disabled="buttonLoading" @click="updatePorfolioLikeStatus(portfolio.uuid, portfolio.likeStatus.action)" buttonType="border"  style="display:flex; align-items:center; justify-content:center;color:#8b95a1;width: 98px; height: 40px;font-size: 14px;">
                <img id="32311" src="https://gcdn.bionluk.com/site/cicons/ic-like-empty.svg" onload="SVGInject(this)" class="like-icon"/>
                <p style="line-height: 1!important; padding-left: 5px">[{{portfolio.fav_count}}]</p>
              </custom-button>
            </div>
            <div v-show="portfolio.likeStatus.action === -1">
              <custom-button :disabled="buttonLoading" @click="updatePorfolioLikeStatus(portfolio.uuid, portfolio.likeStatus.action)"  buttonType="red"  style="display:flex; align-items:center; justify-content:center;color:#fff;width: 98px; height: 40px;font-size: 14px;">
                <img id="123f" src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="like-icon-white"/>
                <p style="line-height: 1!important; padding-left: 5px">[{{portfolio.fav_count}}]</p>
              </custom-button>
            </div>
            <custom-button :disabled="buttonLoading" @click="sendMessage(portfolio.user)" buttonType="dark" style=" margin-left: 20px;width: 128px; height: 38px;font-size: 14px; padding-bottom: 2px;">Mesaj Gönder</custom-button>
          </template>

        </div>


      </div>

      <div class="stats-area">
        <div @click="likeOrShowModal" style="display: flex" :style="!portfolio.fav_count && portfolio.user.username === user.username ? 'cursor:default!important; text-decoration:none!important' : ''">
          <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="red-icon-liked"/>
          <p v-if="!user || (portfolio.user.username === user.username || portfolio.fav_count)" class="number">[{{portfolio.fav_count}}]</p>
          <p class="textt" >{{portfolio.user.username === user.username || portfolio.fav_count ? 'Beğeni' : 'İlk beğenen sen ol'}}</p>
        </div>
        <div style="display: flex; margin-left: 20px;">
          <img src="https://gcdn.bionluk.com/site/cicons/ic-eye-open.svg" onload="SVGInject(this)" class="eye-icon"/>
          <p class="number">{{portfolio.viewCount}}</p>
          <p class="textt">Görüntülenme</p>
        </div>
      </div>



      <div style="position: relative; cursor: pointer;" @click="showPortfolioDetailModal(portfolio.uuid)" >
        <div class="show-attachment" >
          <img src="https://gcdn.bionluk.com/site/cicons/ic-search.svg" onload="SVGInject(this)" class="show-attachment-icon"/>
        </div>
        <div  v-if="portfolio.video_url && (portfolio.file_type === 'audio' || portfolio.file_type === 'video')" class="playIcon" style="text-align: center; width: 100%; margin: 0 auto; ">
            <span style=" cursor:pointer;position: absolute; margin-top: 183px; margin-left: -40px; display: inline-block;height: 80px; width: 80px; border: 1px solid white; background-color: white; border-radius: 50%; ">
              <img style=" flex:1;height: 80px; width: 80px; " :src="$root.CDN_SITE + 'icon/ic_play_circle_fill.svg'">
            </span>
        </div>
        <img class="poster" :style="{cursor:'pointer', width: '793px'}"
             :src="portfolio.items[0].cover.image_url"/>
      </div>


    </div>
  </div>
</template>

<script>

  import Loader from "../toolbox/loader.vue";
  import CustomButton from "@/pages/components/CustomButton";


  export default {
    name: "src-pages-components-shared-navFooter",
    components:{Loader, CustomButton},
    props: ["uuid"],
    data() {
      return {
        buttonLoading:false,
        loader:true,
        hideIt:false,
        portfolio:{
          rating_user:{},
          user:{
            sellerRating:{}
          },
          likeStatus:{},
          items:[{cover:{image_url:null}}],
          cover:{},
          comments:{}
        },
      };
    },
    methods: {
      sendMessage(user) {
        if (!this.$store.state.user.uuid) {
          this.$router.push("/login");
        } else {

          if(this.buttonLoading === false){
            this.buttonLoading = true;
            this.api.workstation.chatOpen(user.username, 0,1)
                .then(({data}) => {
                  let result = data;
                  if (result.success) {
                    let conversationSendData = {
                      r_username: user.username,
                      r_avatar_url: user.avatar_url,
                      r_id: user.user_id,
                      r_uuid: user.uuid,
                      fromWhere: 'Blog Portfolio Box',
                      c_uuid: result.data.c_uuid,
                    };
                    this.EventBus.$emit('externalOpenChat', conversationSendData);
                  } else {
                    this.$toasted.global.errorToast({description: result.message});
                  }
                  this.buttonLoading = false;
                })
                .catch(err => {
                  this.buttonLoading = false;
                  this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
                });
          }
        }

      },
      likeOrShowModal(){
        if(this.portfolio.fav_count || this.portfolio.user.username === this.user.username){
          if(!this.portfolio.fav_count) return false;
        } else {
          this.updatePorfolioLikeStatus(this.portfolio.uuid, this.portfolio.likeStatus.action)
        }
      },
      updatePorfolioLikeStatus(uuid, action) {
        this.api.general.updateLikeStatus(this.Constants.FAV_TYPES.PORTFOLIO, action, uuid, null, )
            .then(({data}) => {
              let result = data;

              if (result.success) {
                result.data.created_at = this.calculateElapsedTime(+new Date() - 1000)
                this.portfolio.fav_count += action;

              } else {

                this.revertLikeStatus();
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              this.revertLikeStatus();
            });
        this.reverseLikeStatus();
      },
      revertLikeStatus() {
        let newLikeIconText = "Beğen";
        let newLikedIconText = "Beğen";
        if (this.portfolio.likeStatus.action === this.imageUrls.likeIcon.action) {
          this.portfolio.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: newLikeIconText
          };
        } else {
          this.portfolio.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: newLikedIconText
          };
        }
      },
      reverseLikeStatus() {
        let newLikeIconText = "Beğen";
        let newLikedIconText = "Beğen";
        if (this.portfolio.likeStatus.action === this.imageUrls.likeIcon.action) {
          this.portfolio.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: newLikedIconText
          };
        } else {
          this.portfolio.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: newLikeIconText
          };
        }
      },
      showPortfolioDetailModal(uuid) {
        // this.$store.state.showCase.data = portfolio;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: uuid}, description: 'keepOldModal'});
      },
    },
    beforeDestroy () {
      this.EventBus.$off('updatePortfolio');
    },
    created() {
      this.api.seller.getPortfolio(this.uuid)
        .then(({data}) => {
          let result = data;

          if (result.success) {
            this.portfolio = result.data.portfolio;
          } else {
            this.hideIt = true;
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        })
        .finally(() => {
          this.loader = false;
        })


      this.EventBus.$on('updatePortfolio', payload => {

        let uuid = payload.uuid;
        let what = payload.what;
        let type = payload.type;

        if(what==='comment' || what==='fav_count'){
          if (this.portfolio.uuid === uuid) {
            if(what==='comment'){
              if(type === 'plus') this.portfolio.comment_count += 1;
              else  this.portfolio.comment_count -= 1;
            }


            if(what==='fav_count'){
              if(type === 'plus') this.portfolio.fav_count += 1;
              else  this.portfolio.fav_count -= 1;
            }

          }
        }


      });

    }
  }
</script>

<style scoped lang="scss">


.poster{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.like-icon /deep/ {
  width: 14px;
  height: 14px;
  margin-top: 2px;
  path {
    fill: #8b95a1!important;
  }
}

.like-icon-white /deep/ {
  width: 14px;
  height: 14px;
  margin-top: 2px;
  path {
    fill: #fff!important;
  }
}



.show-attachment{

  cursor: pointer;
  position: absolute;
  bottom: 25px;
  left: 25px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    opacity: 0.8;
  }

  .show-attachment-icon /deep/ {
    width: 26px;
    height: 26px;
    path {
      fill: #2d3640!important;
    }
  }
}

.portfolio-modal-big-img {
  width: 793px;
  height: 447px;
  margin-top: 16px;
  background-color: #ffffff;
}

.stats-area{

  display: flex;
  padding: 0 30px 20px;


  div{
    &:first-child {
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  .number{
    margin-left: 5px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: #2d3640;
  }

  .textt{
    margin-left: 5px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #5e6b79;
  }

  .red-icon-liked /deep/ {
    width: 14px;
    height: 14px;
    margin-top: 6px;
    path {
      fill: #fd4056!important;
    }
  }

  .eye-icon /deep/ {
    width: 16px;
    height: 16px;
    margin-top: 5px;
    path {
      fill: #1da1f2!important;
    }
  }
}

.user-info-div {

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 20px;



  .avatar {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    border:2px solid #bfc8d2
  }

  .title-and-username {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-self: center;

    .title {

      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.56px;
      color: #2d3640;

    }

    .sub-title {
      margin-top: -8px;
      font-size: 16px;
      font-weight: 300;
      color: #8b95a1;
      a{
        font-size: 16px;
        font-weight: 600;
        color: #fa4a5e;
        &:hover{
          text-decoration: underline;
        }
      }
    }

  }

}


  .portfolio-modal-review-main {
    width: 793px;
    background: white;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;

  }


  .loader-div {
    position: relative;
    width: 100%;
    height: 100%;
  img {
    width: 100%;
    object-fit: cover
  }

  div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  }


</style>
